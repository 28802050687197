<template>
  <div class="form-group">
    <label :for="id">
      {{ textFloat }}
      <font color="red" v-if="required">*</font>
    </label>
    <label v-b-modal.modal-1 class="label-date-picker">
      <b-input-group class="label-date-picker-group">
        <b-form-input
          :class="['input-text', 'input-date', { 'error-input': isError }]"
          :id="id"
          v-model="dateTextDisplay"
          type="text"
          :placeholder="placeholder"
          autocomplete="off"
          disabled
        ></b-form-input>
        <b-input-group-append>
          <div
            class="btn-custom d-flex justify-content-center align-items-center"
          >
            <svg
              id="calendar"
              xmlns="http://www.w3.org/2000/svg"
              width="18.906"
              height="18.906"
              viewBox="0 0 18.906 18.906"
            >
              <g id="Group_1735" data-name="Group 1735">
                <g id="Group_1734" data-name="Group 1734">
                  <path
                    id="Path_8809"
                    data-name="Path 8809"
                    d="M17.245,2.216h-.554V1.662a1.662,1.662,0,0,0-3.323,0v.554H11.152V1.662a1.662,1.662,0,0,0-3.323,0v.554H5.539V1.662a1.662,1.662,0,0,0-3.323,0v.554H1.662A1.664,1.664,0,0,0,0,3.877V17.245a1.664,1.664,0,0,0,1.662,1.662H17.245a1.664,1.664,0,0,0,1.662-1.662V3.877A1.664,1.664,0,0,0,17.245,2.216Zm-2.769-.554a.554.554,0,0,1,1.108,0V3.877a.554.554,0,0,1-1.108,0Zm-5.539,0a.554.554,0,0,1,1.108,0V3.877a.554.554,0,0,1-1.108,0Zm-5.613,0a.554.554,0,0,1,1.108,0V3.877a.554.554,0,0,1-1.108,0ZM17.8,17.245a.555.555,0,0,1-.554.554H1.662a.555.555,0,0,1-.554-.554V7.828H17.8Zm0-10.524H1.108V3.877a.555.555,0,0,1,.554-.554h.554v.554a1.662,1.662,0,0,0,3.323,0V3.323H7.828v.554a1.662,1.662,0,0,0,3.323,0V3.323h2.216v.554a1.662,1.662,0,0,0,3.323,0V3.323h.554a.555.555,0,0,1,.554.554Z"
                    fill="#fff"
                  />
                </g>
              </g>
              <g
                id="Group_1737"
                data-name="Group 1737"
                transform="translate(2.321 8.462)"
              >
                <g id="Group_1736" data-name="Group 1736">
                  <path
                    id="Path_8810"
                    data-name="Path 8810"
                    d="M73.718,242H60.546a.564.564,0,0,0-.546.58v6.963a.564.564,0,0,0,.546.58H73.718a.564.564,0,0,0,.546-.58V242.58A.564.564,0,0,0,73.718,242Zm-10.444,6.963H61.092v-2.321h2.183Zm0-3.481H61.092V243.16h2.183Zm3.348,3.481H64.367v-2.321h2.256Zm0-3.481H64.367V243.16h2.256Zm3.275,3.481H67.714v-2.321H69.9Zm0-3.481H67.714V243.16H69.9Zm3.275,3.481H70.989v-2.321h2.183Zm0-3.481H70.989V243.16h2.183Z"
                    transform="translate(-60 -242)"
                    fill="#fff"
                  />
                </g>
              </g>
            </svg>
          </div>
        </b-input-group-append>
      </b-input-group>
    </label>
    <b-modal
      centered
      id="modal-1"
      hide-header
      @ok="handleOk"
      @show="resetModal"
      no-close-on-esc
      no-close-on-backdrop
      ref="modal-calendar"
    >
      <template v-slot:modal-ok>
        ตกลง
      </template>
      <template v-slot:modal-cancel>
        ยกเลิก
      </template>
      <div class="d-flex d-flex-column current-month  mb-1">
        <b-form-select
          :class="['btn-select']"
          v-model="date.month"
          ref="month"
          @change="handleChangeMonth"
        >
          <b-form-select-option
            v-for="(item, key) in list.month"
            v-bind:key="key"
            :name="`${name}-month`"
            :value="item"
            >{{ item.text }}</b-form-select-option
          >
        </b-form-select>
        <b-form-select
          :class="['btn-select']"
          v-model="date.year"
          ref="year"
          @change="handleChangeYear"
        >
          <b-form-select-option
            v-for="(item, key) in list.year"
            v-bind:key="key"
            :name="`${name}-year`"
            :value="item"
            >{{ item.text }}</b-form-select-option
          >
        </b-form-select>
      </div>
      <b-calendar
        v-model="dateText"
        @context="onChangeDatePic"
        locale="th-TH"
        :hide-header="true"
        width="100%"
        :max="maxDate"
        :min="minDate"
        class="w-100"
      ></b-calendar>
      <!-- <div class="d-flex justify-content-end">
        <b-button
          class="mx-1 mt-2 btn-cancel"
          variant="outline"
          @click="$bvModal.hide('modal-1')"
          >ยกเลิก</b-button
        >
        <b-button class="mx-1 mt-2 btn-ok" @click="handleOk()">ตกลง</b-button>
      </div> -->
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      required: true,
      type: String
    },
    required: {
      required: false,
      type: Boolean
    },
    textFloat: {
      required: false,
      type: String
    },
    placeholder: {
      required: false,
      type: String
    },

    isError: {
      required: false,
      type: Boolean
    },
    value: {
      required: false,
      type: String
    },
    name: {
      required: false,
      type: String
    },

    isRequired: {
      required: false,
      type: Boolean
    }
  },
  data() {
    return {
      dateText: this.$moment().format("YYYY-MM-DD"),
      dateTextDisplay: "",
      dateTextSuccess: "",
      maxDate: "",
      minDate: "",
      date: {
        month: { value: 0, text: "month" },
        year: { value: 0, text: "ปี" }
      },
      list: {
        month: [
          { value: 1, text: "มกราคม" },
          { value: 2, text: "กุมภาพันธ์" },
          { value: 3, text: "มีนาคม" },
          { value: 4, text: "เมษายน" },
          { value: 5, text: "พฤษภาคม" },
          { value: 6, text: "มิถุนายน" },
          { value: 7, text: "กรกฎาคม" },
          { value: 8, text: "สิงหาคม" },
          { value: 9, text: "กันยายน" },
          { value: 10, text: "ตุลาคม" },
          { value: 11, text: "พฤศจิกายน" },
          { value: 12, text: "ธันวาคม" }
        ],
        year: []
      }
    };
  },
  methods: {
    resetModal() {
      if (this.dateTextSuccess) {
        this.dateText = this.dateTextSuccess;
      }
    },
    handleOk(bvModalEvt) {
      let dateObj = this.$moment(this.dateText, "YYYY-MM-DD");
      if (this.$moment().diff(dateObj) <= 0) {
        bvModalEvt.preventDefault();
      } else {
        let dateObjThai = this.$moment(this.dateText, "YYYY-MM-DD");
        let futureDate = this.$moment(dateObjThai).add(543, "years");
        this.dateTextDisplay = futureDate.format("DD / MM / YYYY");

        if (this.dateTextDisplay) {
          let formattedDate = this.dateText.split("-");
          this.$emit(
            "onDataChange",
            `${formattedDate[2]}/${formattedDate[1]}/${formattedDate[0]}`
          );
          this.dateTextSuccess = this.dateText;
        } else {
          this.$emit("onDataChange", "");
        }
      }
    },

    onChangeDatePic(event) {
      // console.log(event);
      // let month = parseInt(
      //   this.$moment(event.activeYMD, "YYYY-MM-DD").format("M")
      // );
      // let year = parseInt(
      //   this.$moment(event.activeYMD, "YYYY-MM-DD").format("YYYY")
      // );
      /*this.date.year.value = year;
      this.date.year.text = year + 543;
      this.date.month = this.list.month[month - 1];*/

      this.dateText = event.activeYMD;
    },
    handleChangeMonth(val) {
      // console.log("month", val);
      let dateObj = this.$moment(this.dateText, "YYYY-MM-DD");
      var futureDate = this.$moment(dateObj).set("month", val.value - 1);
      this.dateText = futureDate.format("YYYY-MM-DD");
    },
    handleChangeYear(val) {
      // console.log("year", val);
      let dateObj = this.$moment(this.dateText, "YYYY-MM-DD");
      var futureDate = this.$moment(dateObj).set("year", val.value);
      this.dateText = futureDate.format("YYYY-MM-DD");
    }
  },
  watch: {
    dateText() {
      let dateObj = this.$moment(this.dateText, "YYYY-MM-DD");
      this.date.month = this.list.month[parseInt(dateObj.format("MM")) - 1];
      let toIntYear = parseInt(dateObj.format("YYYY"));
      this.date.year = { value: toIntYear, text: toIntYear + 543 };
    }
  },
  created: async function() {
    let year = parseInt(this.$moment(new Date()).format("YYYY"));
    let month = parseInt(this.$moment(new Date()).format("MM"));
    for (var i = year; i > year - 100; i--) {
      this.list.year.push({ value: i, text: i + 543 });
    }
    // this.date.year.value = year;
    // this.date.year.text = year + 543;
    let toIntYear = parseInt(year);
    this.date.year = { value: toIntYear, text: toIntYear + 543 };
    this.date.month = this.list.month[month - 1];

    const dDate = new Date();
    this.maxDate = new Date(new Date().setDate(dDate.getDate()));
    this.minDate = new Date(new Date().setFullYear(year - 99));
    // console.log(this.minDate);
  }
};
</script>

<style scoped>
::v-deep footer .small {
  display: none !important;
}
.input-date {
  border-radius: 25px 0px 0px 25px !important;
}
.date-picker {
  position: absolute;
  width: 100%;
  height: 42px;
  opacity: 0;
}

.btn-custom {
  border-radius: 0 22px 22px 0 !important;
  background-color: #f47306 !important;
  color: white !important;
  width: 60px;
}

.label-date-picker {
  width: 100%;
}
.label-date-picker:focus {
  outline: none;
}
.div-in-date-picker {
  height: 1px;
}

::v-deep .b-calendar-nav [aria-keyshortcuts="Home"] {
  display: none !important;
}
::v-deep .b-calendar-grid-caption {
  display: none !important;
}

.label-date-picker-group {
  z-index: -2;
  margin: 0;
}
.label-date-picker-group :focus {
  outline: -webkit-focus-ring-color auto 0px;
}

::v-deep .modal-footer .btn-primary {
  color: #ffffff;
  background-color: #80c141 !important;
  border-color: #80c141 !important;
}
.input-text {
    width: auto !important;
}
</style>
